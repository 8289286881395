import ky from 'ky';

export const fetchDoctypes = async bearerToken =>
  await ky
    .get(`${process.env.REACT_APP_BACKEND_URL}/v1/types`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'X-Business-ID': 'fdr',
      },
    })
    .json();

export const fetchDocuments = async (bearerToken, { fdrApplicantId }) =>
  await ky
    .get(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/v1/applicant/${fdrApplicantId}/documents`,
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'X-Business-ID': 'fdr',
        },
      }
    )
    .json();

export const uploadDocument = async (
  bearerToken,
  { fileFormData, fdrApplicantId }
) =>
  await ky
    .post(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/v1/applicant/${fdrApplicantId}/document`,
      {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'X-Business-ID': 'fdr',
        },
        body: fileFormData,
      }
    )
    .json();

export const updateMetadata = async (
  bearerToken,
  { newMetadata, fdrApplicantId, documentId }
) => {
  const res = await fetch(
    `${
      process.env.REACT_APP_BACKEND_URL
    }/v1/applicant/${fdrApplicantId}/document/${documentId}/metadata`,
    {
      method: 'put',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'X-Business-ID': 'fdr',
      },
      body: JSON.stringify(newMetadata),
    }
  );
  return await res.json();
};

export const fetchDebtAccounts = async (bearerToken, { fdrApplicantId }) => {
  const res = await fetch(`/api/debtAccounts/${fdrApplicantId}`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  return await res.json();
};
