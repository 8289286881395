import React from 'react';

export const IconFileIllustrator = ({ height = 32, width = 32 }) => (
  <svg width={width} height={height} viewBox="0 0 32 32">
    <path fill="#fff" d="M25 27H7V5h13l5 5v17z" />
    <path
      d="M20 4H7a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9.89zm5 23H7V5h13v4a1 1 0 0 0 1 1h4z"
      fill="#f7931d"
    />
    <path
      d="M14 14.85L12.89 18h2.25zm2.16 6l-.68-2h-2.83l-.71 2H11l2.61-7.1h.88l2.61 7.1zM18.99 13.7h.89v7.1h-.89z"
      fill="#f7931d"
    />
  </svg>
);
