const ClientOAuth2 = require('client-oauth2');

const oktaAuth = new ClientOAuth2({
  clientId: process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  accessTokenUri: `${process.env.REACT_APP_OKTA_BASE_URL}/v1/token`,
  authorizationUri: `${process.env.REACT_APP_OKTA_BASE_URL}/v1/authorize`,
  redirectUri: `${process.env.REACT_APP_API_HOST}/authorization-code/callback`,
  scopes: ['openid', 'profile'],
});

module.exports = oktaAuth;
