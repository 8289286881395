import 'styled-components/macro';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

export default class Upload extends Component {
  state = { files: [], isUploading: false, isInvalid: true };
  addNewFiles = (fileObjs, rejectedFiles) => {
    if (rejectedFiles.length) {
      this.props.onFilesRejected(rejectedFiles);
    }
    const newFiles = fileObjs.map(fileObj => ({
      fileObj,
      attributes: {},
      name: fileObj.name,
      size: fileObj.size,
      isInvalid: true,
    }));
    this.setState(prevState => {
      const changeFiles = prevState.files.concat(newFiles);
      return {
        files: changeFiles,
        isInvalid: changeFiles.some(o => o.isInvalid),
      };
    });
  };
  uploadFile = file => {
    const fileFormData = new FormData();
    fileFormData.append('file', file.fileObj);
    fileFormData.append('attributes', JSON.stringify(file.attributes));
    let uploadUrl = `${process.env.REACT_APP_BACKEND_URL}/v1/applicant`;
    return fetch(`${uploadUrl}/${this.props.applicantId}/document`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.bearerToken}`,
        'X-Business-ID': 'fdr',
      },
      body: fileFormData,
    }).then(res => res.json());
  };
  uploadFiles = () => {
    this.setState({ isUploading: true });
    Promise.all(this.state.files.map(this.uploadFile))
      .then(() => {
        this.props.onUploadComplete();
        this.setState({ isUploading: false, files: [], isInvalid: true });
      })
      .catch(err => {
        this.props.onUploadFailed(err);
        this.setState({ isUploading: false, files: [], isInvalid: true });
      });
  };
  removeFile = file =>
    this.setState(prevState => {
      const changeFiles = prevState.files.filter(item => item !== file);
      return {
        files: changeFiles,
        isInvalid: changeFiles.length
          ? changeFiles.some(o => o.isInvalid)
          : true,
      };
    });
  clearFiles = () => this.setState({ files: [], isInvalid: true });
  updateFile = file => {
    this.setState(prevState => {
      const changeFiles = prevState.files.map(item =>
        item.fileObj.lastModified === file.fileObj.lastModified &&
        item.fileObj.name === file.fileObj.name
          ? file
          : item
      );
      return {
        files: changeFiles,
        isInvalid: changeFiles.length
          ? changeFiles.some(o => o.isInvalid)
          : true,
      };
    });
  };
  render() {
    const { files, isUploading, isInvalid } = this.state;
    return (
      <Dropzone
        disableClick
        maxSize={1024 * 1024 * 128}
        onDrop={this.addNewFiles}
        style={{ height: '100%' }}
      >
        {({ getRootProps, getInputProps, open }) => (
          <div css="height: 100%;" {...getRootProps()}>
            <input {...getInputProps()} />
            {this.props.children({
              files,
              openFilesDialog: open,
              clearFiles: this.clearFiles,
              removeFile: this.removeFile,
              updateFile: this.updateFile,
              uploadFiles: this.uploadFiles,
              isInvalid,
              isUploading,
            })}
          </div>
        )}
      </Dropzone>
    );
  }
}
