import 'styled-components/macro';
import * as R from 'ramda';
import React, { useState } from 'react';
import { Interval, DateTime } from 'luxon';
import fileExtension from 'file-extension';
import { Input, DateRangePicker } from 'rsuite';

import { fromSortObj, whereAny } from '../../lib/utils';
import ExpandedRow from './ExpandedRow';
import { ActionCell } from './Cells';
import { GridTable, Column, Cell, Header, Sortable, FileIcon } from '../';

const InputFilterHeader = ({
  sortKey,
  sortState,
  sortable,
  onFilter,
  title,
}) => (
  <>
    <div>
      {title}
      {sortable && <Sortable sortKey={sortKey} sortState={sortState} />}
    </div>
    <Input size="sm" onChange={onFilter} onClick={e => e.stopPropagation()} />
  </>
);
const DateFilterHeader = ({
  sortKey,
  sortState,
  sortable,
  onFilter,
  title,
}) => (
  <>
    <div>
      {title}
      {sortable && <Sortable sortKey={sortKey} sortState={sortState} />}
    </div>
    <div onClick={e => e.stopPropagation()}>
      <DateRangePicker
        placement="bottomRight"
        onChange={values => {
          if (values.length) {
            const filterValue = Interval.fromDateTimes(...values);
            onFilter(val => filterValue.contains(val));
          } else {
            onFilter(null);
          }
        }}
        block
        css={`
          .rs-picker-toggle {
            height: 30px;
            padding: 4px 11px;
            padding-right: 30px;
            font-size: 14px;
            line-height: 1.42857143;
          }
          .rs-picker-toggle-caret {
            top: 4px !important;
          }
        `}
      />
    </div>
  </>
);

export const DocumentTable = ({
  data,
  loading,
  previewToggle,
  updatePreview,
  editMetadata,
}) => {
  const [list, setList] = useState([]);
  const [filters, setFilters] = useState({});
  const [sortState, setSortState] = useState({ time: 'descend' });

  const updateFilter = prop => value =>
    setFilters(state => ({ ...state, [prop]: value }));

  const sortedAndFilteredData = R.pipe(
    R.filter(whereAny(filters)),
    R.sortWith([
      ...fromSortObj(sortState),
      R.descend(
        R.pipe(
          R.prop('time'),
          o => o.toMillis()
        )
      ),
    ])
  )(data);

  return (
    <GridTable
      data={sortedAndFilteredData}
      onSortColumn={setSortState}
      sortState={sortState}
      onRowClick={({ id }) => {
        setList(state => {
          if (state.find(item => item === id)) {
            return state.filter(item => item !== id);
          }
          return state.concat(id);
        });
      }}
      renderRowExpanded={item => <ExpandedRow {...item} />}
      expandedRowKeys={list}
      loading={loading}
    >
      <Column width={64} align="center">
        <Header />
        <Cell accessor={rowData => rowData.metadata.name}>
          {({ cellValue }) => (
            <div css="padding: 7px 0px 0px 16px">
              <FileIcon extension={fileExtension(cellValue)} />
            </div>
          )}
        </Cell>
      </Column>
      <Column flexGrow={2} sortable>
        <Header css="padding-bottom: 5px;" sortKey="metadata.name">
          {sortProps => (
            <InputFilterHeader
              {...sortProps}
              title="Name"
              onFilter={updateFilter('metadata.name')}
            />
          )}
        </Header>
        <Cell accessor={rowData => rowData.metadata.name} />
      </Column>
      <Column flexGrow={2} sortable>
        <Header css="padding-bottom: 5px;" sortKey="doctypeLabel">
          {sortProps => (
            <InputFilterHeader
              {...sortProps}
              title="Document Type"
              onFilter={updateFilter('doctypeLabel')}
            />
          )}
        </Header>
        <Cell accessor="doctypeLabel" />
      </Column>
      <Column flexGrow={2} sortable>
        <Header css="padding-bottom: 5px;" sortKey="time">
          {sortProps => (
            <DateFilterHeader
              {...sortProps}
              title="Date Uploaded"
              onFilter={updateFilter('time')}
            />
          )}
        </Header>
        <Cell
          accessor={rowData =>
            rowData.time.toLocaleString(DateTime.DATETIME_FULL)
          }
        />
      </Column>
      <Column flexGrow={1}>
        <Header />
        <Cell accessor={R.identity}>
          {({ rowData }) => (
            <div
              css={`
                height: 100%;
                display: flex;
                align-items: center;
              `}
            >
              <ActionCell
                previewToggle={previewToggle}
                updatePreview={updatePreview}
                editMetadata={editMetadata}
                rowData={rowData}
              />
            </div>
          )}
        </Cell>
      </Column>
    </GridTable>
  );
};

export default DocumentTable;
