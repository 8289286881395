import 'styled-components/macro';
import React, { useMemo } from 'react';
import { Column } from './Column';
import { NoData } from './NoData';
import { Loading } from './Loading';
import * as R_ from 'ramda-extension';

import {
  createHeaders,
  createRowTemplate,
  createRows,
  isChildType,
  getGridTemplateColumns,
} from './utils';

const Headers = React.memo(({ columns, wordWrap, sortState, onSortColumn }) =>
  createHeaders(columns, {
    wordWrap,
    sortState,
    onSortColumn,
  })
);

const Rows = React.memo(
  ({
    rowKey,
    columns,
    wordWrap,
    expandedRowKeys,
    data,
    onRowClick,
    renderRowExpanded,
  }) => {
    const expandKeys = R_.valueMirror(expandedRowKeys);

    const rowTemplate = useMemo(
      () => createRowTemplate(columns, { wordWrap }),
      [columns, wordWrap]
    );

    const rows = useMemo(
      () =>
        createRows(
          rowTemplate,
          { onRowClick, renderRowExpanded, rowKey, expandKeys },
          data
        ),
      [rowTemplate, onRowClick, renderRowExpanded, rowKey, expandKeys, data]
    );

    return rows;
  }
);

export const GridTable = ({
  children,
  data,
  showHeader,
  wordWrap,
  noDataMessage,
  loading,
  loadingMessage,
  renderRowExpanded,
  sortState,
  onSortColumn,
  onRowClick,
  expandedRowKeys,
  rowKey,
}) => {
  const columns = Array.isArray(children) ? children : [children];
  if (!columns.every(isChildType(Column))) {
    throw new Error('Children of GridTable must be of type Column');
  }
  const gridTemplateColumns = getGridTemplateColumns(columns);
  const gridTemplateRows = showHeader ? `auto 1fr` : '1fr';

  return (
    <div
      id="ffn-table"
      css={`
        width: 100%;
        height: 100%;
        font-size: 14px;
        display: grid;
        grid-template-columns: ${gridTemplateColumns};
        grid-template-rows: ${gridTemplateRows};
      `}
    >
      {showHeader && (
        <div id="ffn-table-headers" css="display: contents;">
          <Headers
            columns={columns}
            wordWrap={wordWrap}
            sortState={sortState}
            onSortColumn={onSortColumn}
          />
        </div>
      )}
      <>
        {data.length ? (
          <div
            id="ffn-table-body"
            css={`
              width: 100%;
              max-height: 100%;
              grid-column: 1 / -1;
              grid-row: 2;
              display: grid;
              grid-template-columns: ${gridTemplateColumns};
              grid-auto-rows: min-content;
              overflow-y: scroll;
            `}
          >
            <Rows
              rowKey={rowKey}
              columns={columns}
              wordWrap={wordWrap}
              expandedRowKeys={expandedRowKeys}
              data={data}
              onRowClick={onRowClick}
              renderRowExpanded={renderRowExpanded}
            />
          </div>
        ) : (
          <NoData noDataMessage={noDataMessage} />
        )}
        {loading && <Loading loadingMessage={loadingMessage} />}
      </>
    </div>
  );
};
GridTable.defaultProps = {
  showHeader: true,
  data: [],
  sortState: {},
  expandedRowKeys: [],
  rowKey: 'id',
};

export * from './Column';
export * from './Cell';
export * from './Header';
