import React, { Component } from 'react';
import { ObjectValue } from 'react-values';
import { EditFile } from '../';
import { fetchDoctypes } from '../../lib/doctypes';
import { fetchDebtAccounts } from '../../lib/api';

class AttributesEditor extends Component {
  state = { doctypes: [], picker: 'group', debtAccounts: [] };
  async componentDidMount() {
    let promises = [
      fetchDoctypes(
        this.props.bearerToken,
        { upload: true },
        this.state.picker
      ).then(doctypes =>
        this.setState({
          doctypes,
        })
      ),
    ];
    if (this.props.fromSalesforce) {
      promises = promises.concat(
        fetchDebtAccounts(this.props.bearerToken, {
          fdrApplicantId: this.props.fdrApplicantId,
        }).then(debtAccounts =>
          this.setState({
            debtAccounts: Object.entries(debtAccounts).map(
              ([value, label]) => ({
                label,
                value,
              })
            ),
          })
        )
      );
    }
    await Promise.all(promises);
  }
  render() {
    const {
      file,
      saveFile,
      cancel,
      dropdownSelector,
      fromSalesforce,
    } = this.props;
    const { doctypes, debtAccounts, picker } = this.state;
    return (
      <ObjectValue defaultValue={file}>
        {({ value, set }) => (
          <EditFile
            fromSalesforce={fromSalesforce}
            debtAccounts={debtAccounts}
            dropdownSelector={dropdownSelector}
            doctypes={doctypes}
            file={value}
            setDoctype={doctype => {
              set('attributes', {
                document_type: doctype,
              });
              if (
                [
                  'notarized_partner_atc',
                  'partner_atc',
                  'power_of_attorney',
                ].includes(doctype)
              ) {
                set('isInvalid', true);
              } else {
                set('isInvalid', false);
              }
            }}
            setPartnerName={partnerName => {
              set('attributes', {
                ...value.attributes,
                partner_name: partnerName,
              });
              set('isInvalid', false);
            }}
            setDebtAccount={debtAccountId => {
              set('attributes', {
                ...value.attributes,
                fdr_sf_debt_account_id: debtAccountId,
              });
            }}
            saveFile={() => saveFile(value)}
            clearEditFile={cancel}
            picker={picker}
          />
        )}
      </ObjectValue>
    );
  }
}

export default AttributesEditor;
