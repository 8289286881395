import 'styled-components/macro';
import React from 'react';
import { isFunction } from 'ramda-extension';

export const Layout = ({ children }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const fromSalesforce = searchParams.get('from') === 'salesforce';
  return (
    <div
      css={`
        width: 100%;
        height: 100vh;
        display: grid;
        grid-template-rows: 66px 1fr;
        grid-template-columns: 1fr;
      `}
    >
      <div>
        {isFunction(children[0]) ? children[0](fromSalesforce) : children[0]}
      </div>
      <div
        css={`
          display: block;
          overflow: hidden;
          padding-left: 10px;
          padding-right: 10px;
        `}
      >
        {isFunction(children[1]) ? children[1](fromSalesforce) : children[1]}
      </div>
    </div>
  );
};
