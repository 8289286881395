import 'styled-components/macro';
import React, { useMemo } from 'react';
import * as R from 'ramda';
import * as R_ from 'ramda-extension';

export const createHeaders = (columns, tableProps) =>
  R_.mapIndexed((child, colIndex) => {
    const columnProps = R.pick(
      ['align', 'wordWrap', 'sortable', 'sortKey'],
      child.props
    );
    return React.cloneElement(child.props.children[0], {
      ...tableProps,
      ...columnProps,
      ...child.props.children[0].props,
      key: `col${colIndex}header`,
    });
  })(columns);

export const createRowTemplate = (columns, tableProps) =>
  R.map(child => {
    const columnProps = R.pick(['align', 'wordWrap'], child.props);
    return React.cloneElement(child.props.children[1], {
      ...tableProps,
      ...columnProps,
      ...child.props.children[1].props,
    });
  })(columns);

const Row = React.memo(({ rowTemplate, rowData, rowKey, onRowClick }) => {
  const row = useMemo(
    () => (
      <div
        id={`row-${rowData[rowKey]}`}
        css={`
          display: contents;
          &:hover {
            > * {
              background: #ebf9ff;
            }
          }
        `}
        onClick={() => onRowClick(rowData)}
      >
        {rowTemplate.map((elm, colIndex) =>
          React.cloneElement(elm, {
            rowData,
            key: `row${rowData[rowKey]}col${colIndex}`,
          })
        )}
      </div>
    ),
    [rowData, rowKey, rowTemplate, onRowClick]
  );
  return row;
});

export const createRows = (
  rowTemplate,
  { onRowClick, renderRowExpanded, rowKey, expandKeys },
  data
) =>
  R.pipe(
    R_.mapIndexed(rowData => (
      <>
        <Row
          key={`row${rowData[rowKey]}wrapper`}
          rowTemplate={rowTemplate}
          rowData={rowData}
          onRowClick={onRowClick}
          rowKey={rowKey}
        />
        {expandKeys[rowData[rowKey]] && (
          <div
            css={`
              grid-column: 1 / -1;
              color: #575757;
              border-bottom: 1px solid #f2f2f5;
            `}
            key={`expandedRow${rowData[rowKey]}`}
          >
            {renderRowExpanded(rowData)}
          </div>
        )}
      </>
    ))
  )(data);

export const isChildType = type => child => child.type === type;

export const getGridTemplateColumns = childArray =>
  childArray
    .map(({ props: { width, flexGrow, minWidth } }, index) => {
      if (width) {
        if (index === childArray.length - 1) {
          return `minmax(${width}px, 1fr)`;
        }
        return width + 'px';
      } else if (flexGrow) {
        // TODO flex grow can't be less than 1
        return flexGrow + 'fr';
      }
      return `minmax(${minWidth}px, 1fr)`;
    })
    .join(' ');
