import React from 'react';

export const IconFileDocument = ({ height = 32, width = 32 }) => (
  <svg width={width} height={height} viewBox="0 0 32 32">
    <path fill="#fff" d="M25 27H7V5h13l5 5v17z" />
    <path
      d="M20 4H7a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9.89zm5 23H7V5h13v4a1 1 0 0 0 1 1h4z"
      fill="#2a62b9"
    />
    <path d="M10 20h8v1h-8zm0-3h11v1H10zm0-3h11v1H10z" fill="#2a62b9" />
  </svg>
);
