import React from 'react';

export const IconFileCode = ({ height = 32, width = 32 }) => (
  <svg width={width} height={height} viewBox="0 0 32 32">
    <path fill="#fff" d="M25 27H7V5h13l5 5v17z" />
    <path
      fill="#e33d55"
      d="M20 19h1v1h-1zM20 13h-3v1h2v3h1v-4zM19 23h-2v1h3v-4h-1v3zM21 18h1v1h-1zM20 17h1v1h-1zM11 24h3v-1h-2v-3h-1v4zM10 19h1v1h-1zM10 17h1v1h-1zM9 18h1v1H9zM12 14h2v-1h-3v4h1v-3z"
    />
    <path
      d="M20 4H7a.94.94 0 0 0-1 1v22a.94.94 0 0 0 .88 1H25a.94.94 0 0 0 1-1V9.9zm5 23H7V5h13v4a.94.94 0 0 0 .88 1H25z"
      fill="#e33d55"
    />
  </svg>
);
