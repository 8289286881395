import { useEffect } from 'react';
import { useList, useSetState } from 'react-use';

const namespacePrefix = 'expire_mixin';

export const useSet = () => {
  const [list, { push, set, filter, sort }] = useList();
  return [
    list,
    {
      push,
      set,
      filter,
      sort,
      has: item => list.some(obj => obj === item),
      remove: item => filter(obj => obj !== item),
    },
  ];
};

const defaultOpts = {};

export const useFetch = (input, opts = defaultOpts) => {
  const [state, setState] = useSetState({
    error: null,
    loading: true,
    data: null,
  });
  const { readBody = body => body.json(), ...init } = opts;
  useEffect(
    () => {
      (async () => {
        setState({ error: null, loading: true, data: null });
        try {
          const response = await fetch(input, {
            ...init,
          });
          if (response.ok) {
            const body = await readBody(response);
            setState({ data: body, loading: false });
          } else {
            setState({ error: new Error(response.statusText), loading: false });
          }
        } catch (e) {
          setState({ error: e, loading: false });
        }
      })();
      return () => {};
    },
    [init, input, opts, readBody, setState]
  );
  return {
    ...state,
  };
};

export const getExpiryLocalStorage = key => {
  const _expires =
    localStorage.getItem('__storehook_' + namespacePrefix + '__' + key) ||
    Number.MAX_VALUE;

  const _value = localStorage.getItem(key);
  const expireValue = _expires > new Date().getTime() ? _value : null;
  return expireValue;
};

export const setExpiryLocalStorage = (value, key, time) => {
  localStorage.setItem('accessToken', value);
  if (time) {
    localStorage.setItem('__storehook_' + namespacePrefix + '__' + key, time);
  }
};
