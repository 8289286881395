import React from 'react';
import { Value } from 'react-values';
import Upload from './Upload';
import Dashboard from './Dashboard';
import Placeholder from './Placeholder';
import AttributesEditor from './AttributesEditor';
export default ({
  applicantId,
  onUploadComplete,
  onFilesRejected,
  bearerToken,
  dropdownSelector,
  onUploadFailed,
  fromSalesforce,
}) => (
  <Upload
    applicantId={applicantId}
    onUploadComplete={onUploadComplete}
    onFilesRejected={onFilesRejected}
    onUploadFailed={onUploadFailed}
    bearerToken={bearerToken}
  >
    {({
      files,
      openFilesDialog,
      clearFiles,
      removeFile,
      uploadFiles,
      updateFile,
      isUploading,
      isInvalid,
    }) =>
      files.length ? (
        <Value>
          {({ value, set, clear }) =>
            value ? (
              <AttributesEditor
                fdrApplicantId={applicantId}
                fromSalesforce={fromSalesforce}
                dropdownSelector={dropdownSelector}
                file={value}
                saveFile={file => {
                  updateFile(file);
                  clear();
                }}
                cancel={clear}
                bearerToken={bearerToken}
              />
            ) : (
              <Dashboard
                files={files}
                openFileDialog={openFilesDialog}
                clearFiles={clearFiles}
                removeFile={removeFile}
                uploadFiles={uploadFiles}
                editFile={set}
                isUploading={isUploading}
                isInvalid={isInvalid}
              />
            )
          }
        </Value>
      ) : (
        <Placeholder openFileDialog={openFilesDialog} />
      )
    }
  </Upload>
);
