import React, { Fragment } from 'react';
import { useToggle } from 'react-use';
import { ObjectValue } from 'react-values';
import { Button, InputPicker, Input, Modal } from 'rsuite';
import { formatDoctypes } from '../../lib/doctypes';

const FormGroup = props => <div className="rs-form-group" {...props} />;
const FormControl = props => (
  <div className="rs-form-control-wrapper" {...props} />
);
const FormLabel = props => <label className="rs-control-label" {...props} />;

const FormFileFragment = ({
  title,
  value,
  set,
  setToggle,
  debtAccounts,
  fromSalesforce,
  doctypes,
  setAlertToggle,
}) => (
  <Fragment>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <FormGroup key="name">
        <FormLabel>Name</FormLabel>
        <FormControl>
          <Input
            value={value.name}
            onChange={v => set('name', v)}
            placeholder="Default Input"
          />
        </FormControl>
      </FormGroup>
      <FormGroup key="document_type">
        <FormLabel>Document Type</FormLabel>
        <FormControl>
          <InputPicker
            type="select"
            name="document_type"
            placement="bottomRight"
            value={value.document_type}
            onSelect={t => set('document_type', t)}
            valueKey="slug"
            groupBy="group"
            height={205}
            maxHeight={205}
            data={formatDoctypes(doctypes, 'group')}
            placeholder="Select Document Type"
            block
            cleanable={false}
          />
        </FormControl>
      </FormGroup>
      {['notarized_partner_atc', 'partner_atc', 'power_of_attorney'].includes(
        value.document_type
      ) && (
        <FormGroup key="partner_name">
          <FormLabel>Partner Name</FormLabel>
          <FormControl>
            <InputPicker
              placement="topLeft"
              value={value.partner_name}
              data={[
                { label: 'McCarthy', value: 'McCarthy' },
                { label: 'Zachary', value: 'Zachary' },
                { label: 'NLLG', value: 'NLLG' },
                { label: 'Turnbull', value: 'Turnbull' },
                { label: 'Gitmeid', value: 'Gitmeid' },
              ]}
              onSelect={t => set('partner_name', t)}
              placeholder="Select Partner Name"
              maxHeight={250}
              block
              cleanable={false}
            />
          </FormControl>
        </FormGroup>
      )}
      {fromSalesforce && (
        <FormGroup key="fdr_sf_debt_account_id">
          <FormLabel>Debt Account</FormLabel>
          <FormControl>
            <InputPicker
              placement="topLeft"
              value={value.fdr_sf_debt_account_id}
              data={debtAccounts}
              onSelect={t => set('fdr_sf_debt_account_id', t)}
              placeholder="Select Debt Account"
              maxHeight={250}
              block
              cleanable={false}
            />
          </FormControl>
        </FormGroup>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button
        disabled={!value.name.length}
        onClick={() => setAlertToggle(true)}
        appearance="primary"
      >
        Save
      </Button>
      <Button
        onClick={() => setToggle(false)}
        disabled={false}
        type="reset"
        appearance="subtle"
      >
        Cancel
      </Button>
    </Modal.Footer>
  </Fragment>
);

const AlertFragment = ({ setToggle, setAlertToggle, onSubmit, value }) => (
  <Fragment>
    <Modal.Header>
      <Modal.Title>Are you sure you want to update metadata?</Modal.Title>
    </Modal.Header>
    <Modal.Footer>
      <Button
        disabled={false}
        onClick={() => {
          onSubmit(value);
          setToggle(false);
          setAlertToggle(false);
        }}
        appearance="primary"
      >
        Confirm Update
      </Button>
      <Button
        onClick={() => setAlertToggle(false)}
        disabled={false}
        appearance="subtle"
      >
        Cancel
      </Button>
    </Modal.Footer>
  </Fragment>
);

export const ModalForm = ({
  debtAccounts,
  doctypes,
  title,
  onSubmit,
  children,
  data,
}) => {
  const [on, setToggle] = useToggle(false);
  const [alert, setAlertToggle] = useToggle(false);
  const searchParams = new URLSearchParams(window.location.search);
  const fromSalesforce = searchParams.get('from') === 'salesforce';
  return (
    <Fragment>
      {children({
        showModal: on,
        openModal: () => setToggle(true),
        closeModal: () => setToggle(false),
      })}
      <Modal
        show={on}
        onHide={() => {
          setToggle(false);
          setAlertToggle(false);
        }}
      >
        <ObjectValue defaultValue={data}>
          {({ value, set }) => (
            <div className="rs-form rs-form-vertical rs-form-fluid">
              {alert ? (
                <AlertFragment
                  {...{
                    setAlertToggle,
                    setToggle,
                    onSubmit,
                    value,
                  }}
                />
              ) : (
                <FormFileFragment
                  {...{
                    fromSalesforce,
                    debtAccounts,
                    doctypes,
                    title,
                    setAlertToggle,
                    value,
                    set,
                    setToggle,
                  }}
                />
              )}
            </div>
          )}
        </ObjectValue>
      </Modal>
    </Fragment>
  );
};

ModalForm.defaultProps = {
  data: [],
  customComponentMap: {},
};

export default ModalForm;
