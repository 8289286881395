import * as R from 'ramda';

const defaultPermissions = {
  upload: true,
  view: true,
  download: true,
  edit: true,
};
const permissionFns = {
  upload: R.pathEq(['permission', 'upload'], true),
  view: R.pathEq(['permission', 'view'], true),
  download: R.pathEq(['permission', 'download'], true),
  edit: R.pathEq(['permission', 'edit'], true),
};

const groupMap = [
  {
    slug: 'credit_card_statement',
    group: 'Client Correspondence',
  },
  {
    slug: 'collection_letter',
    group: 'Client Correspondence',
  },
  {
    slug: 'charge_off_notice',
    group: 'Client Settlement',
    subgroup: 'Completed',
  },
  {
    slug: 'loan_statement',
    group: 'Client Correspondence',
  },
  {
    slug: 'paid_in_full_notice',
    group: 'Client Settlement',
    subgroup: 'Completed',
  },
  {
    slug: 'utility_bill',
    group: 'Client Personal',
    subgroup: 'Financial',
  },
  {
    slug: 'fdr_contract',
    group: 'Client Account',
  },
  {
    slug: 'settlement_offer_from_creditor',
    group: 'Client Settlement',
    subgroup: 'In Progress',
  },
  {
    slug: 'sif_settlement_letter',
    group: 'Client Settlement',
    subgroup: 'In Progress',
  },
  {
    slug: 'mortgage_statement',
    group: 'Client Correspondence',
  },
  {
    slug: 'welcome_packet',
    group: 'Client Account',
  },
  {
    slug: 'voice_snippet',
    group: 'FDR Evaluation',
  },
  {
    slug: '1099_c_tax_form',
    group: 'Client Correspondence',
  },
  {
    slug: 'credit_report',
    group: 'Client Personal',
    subgroup: 'Financial',
  },
  {
    slug: 'signed_fdr_contract',
    group: 'Client Account',
  },
  {
    slug: 'tax_return',
    group: 'Client Personal',
    subgroup: 'Financial',
  },
  {
    slug: 'court_documents',
    group: 'Legal Documents',
    subgroup: 'Lawsuit',
  },
  {
    slug: 'fdr_contract_amendments',
    group: 'Client Account',
  },
  {
    slug: 'waivers',
    group: 'Client Account',
  },
  {
    slug: 'proof_of_identity_document',
    group: 'Client Personal',
    subgroup: 'Identity',
  },
  {
    slug: 'deposit_account_authorization_form',
  },
  {
    slug: 'power_of_attorney',
    group: 'Legal Documents',
    subgroup: 'Communication',
  },
  {
    slug: 'third_party_authorization',
    group: 'Legal Documents',
    subgroup: 'Communication',
  },
  {
    slug: 'authorization_to_debit',
  },
  {
    slug: 'draft_modification_form',
  },
  {
    slug: 'contract_amendment',
    group: 'Client Account',
  },
  {
    slug: 'bank_statement',
    group: 'Client Correspondence',
  },
  {
    slug: 'bank_change',
  },
  {
    slug: 'add_co_client',
    group: 'Client Account',
  },
  {
    slug: 'atc_revoke',
  },
  {
    slug: 'judgement_stip_letter',
    group: 'Legal Documents',
    subgroup: 'Lawsuit',
  },
  {
    slug: 'death_certificate',
    group: 'Client Personal',
    subgroup: 'Life Event',
  },
  {
    slug: 'marriage_license',
    group: 'Client Personal',
    subgroup: 'Life Event',
  },
  {
    slug: 'legal_services_contract',
    group: 'Legal Documents',
    subgroup: 'Communication',
  },
  {
    slug: 'summons_and_complaints',
    group: 'Legal Documents',
    subgroup: 'Lawsuit',
  },
  {
    slug: 'bankruptcy_document',
    group: 'Legal Documents',
    subgroup: 'Lawsuit',
  },
  {
    slug: 'settlement_authorization',
    group: 'Client Settlement',
    subgroup: 'In Progress',
  },
  {
    slug: 'bulk_settlement_spreadsheet',
    group: 'Client Personal',
    subgroup: 'Financial',
  },
  {
    slug: 'customer_email_authorization',
    group: 'Client Account',
  },
  {
    slug: 'proof_of_payment',
    group: 'Client Settlement',
    subgroup: 'In Progress',
  },
  {
    slug: 'cft_agreement',
  },
  {
    slug: 'cease_communication',
    group: 'Legal Documents',
    subgroup: 'Communication',
  },
  {
    slug: 'financial_hardship_statement',
    group: 'Client Personal',
    subgroup: 'Life Event',
  },
  {
    slug: 'nps_survey_response',
    group: 'FDR Evaluation',
  },
  {
    slug: 'qa_spreadsheet',
    group: 'FDR Evaluation',
  },
  {
    slug: 'billing_statement',
    group: 'Client Correspondence',
  },
  {
    slug: 'qa_voice_analyzer',
    group: 'FDR Evaluation',
  },
  {
    slug: 'post_call_survey',
    group: 'FDR Evaluation',
  },
  {
    slug: 'student_loan_statement',
    group: 'Client Correspondence',
  },
  {
    slug: 'til_agreement',
    group: 'Legal Documents',
  },
  {
    slug: 'deficiency_statement',
    group: 'Client Personal',
    subgroup: 'Financial',
  },
  {
    slug: 'general_correspondence',
    group: 'Client Correspondence',
  },
  {
    slug: 'settlement_letter_ffam',
    group: 'Client Settlement',
    subgroup: 'In Progress',
  },
  {
    slug: 'cccs_proposal',
  },
  {
    slug: 'scra_request',
    group: 'Legal Documents',
    subgroup: 'Lawsuit',
  },
  {
    slug: 'transition_packet',
    group: 'Client Account',
  },
  {
    slug: 'payoff_letter',
    group: 'Client Settlement',
    subgroup: 'Completed',
  },
  {
    slug: 'statement_of_account',
    group: 'Client Correspondence',
  },
  {
    slug: 'zero_balance_letter',
    group: 'Client Settlement',
    subgroup: 'Completed',
  },
  {
    slug: 'late_notice',
    group: 'Client Correspondence',
  },
  {
    slug: 'signed_privacy_notice_opt_out',
    group: 'Legal Documents',
    subgroup: 'Communication',
  },
  {
    slug: 'authorization_to_communicate',
    group: 'Legal Documents',
    subgroup: 'Communication',
  },
  {
    slug: 'partner_atc',
    group: 'Legal Documents',
  },
  {
    slug: 'hipaa_release',
    group: 'Legal Documents',
  },
  {
    slug: 'funding_screenshot',
    group: 'Legal Documents',
  },
  {
    slug: 'c_d_removal',
    group: 'Legal Documents',
  },
  {
    slug: 'notarized_atc',
    group: 'Legal Documents',
  },
  {
    slug: 'notarized_partner_atc',
    group: 'Legal Documents',
  },
  {
    slug: 'to_delete',
    group: 'Client Account',
  },
  {
    slug: 'salesforce_document',
    group: 'Client Account',
  },
];

export const fetchDoctypes = async (
  bearerToken,
  permissions = defaultPermissions,
  picker
) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/types`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'X-Business-ID': 'fdr',
      },
    });
    const allTypes = await res.json();
    const filters = Object.entries(permissions).reduce(
      (acc, [key, value]) => (value ? [...acc, permissionFns[key]] : acc),
      []
    );
    const typesWithPermissions = R.filter(item => R.anyPass(filters)(item));
    const docTypes = typesWithPermissions(allTypes);
    const groupMapped = R.map(o =>
      R.merge(R.find(R.eqProps('slug', o), groupMap), o)
    )(docTypes);
    if (picker === 'cascader') {
      const toTree = R.reduce(
        (acc, [key, value]) =>
          key !== 'undefined'
            ? [...acc, { label: key, slug: key, children: value }]
            : [...acc, ...value],
        []
      );
      const group = R.groupBy(R.prop('role'), groupMapped);

      const level1 = toTree(Object.entries(group));

      const level2 = level1.map(item => {
        if (item.children) {
          const i = R.groupBy(R.prop('subRole'), item.children);
          const test = { ...item, children: toTree(Object.entries(i)) };
          return test;
        }
        return item;
      });

      return level2;
    } else if (picker === 'group') {
      return groupMapped.map(item =>
        item.group ? item : { ...item, group: 'Other' }
      );
    } else {
      return docTypes;
    }
  } catch (e) {
    console.log('ERROR', e);
    return [];
  }
};

export const formatDoctypes = (docTypes, picker) => {
  const groupMapped = R.map(o =>
    R.merge(R.find(R.eqProps('slug', o), groupMap), o)
  )(docTypes);
  if (picker === 'cascader') {
    const toTree = R.reduce(
      (acc, [key, value]) =>
        key !== 'undefined'
          ? [...acc, { label: key, slug: key, children: value }]
          : [...acc, ...value],
      []
    );
    const group = R.groupBy(R.prop('role'), groupMapped);

    const level1 = toTree(Object.entries(group));

    const level2 = level1.map(item => {
      if (item.children) {
        const i = R.groupBy(R.prop('subRole'), item.children);
        const test = { ...item, children: toTree(Object.entries(i)) };
        return test;
      }
      return item;
    });

    return level2;
  } else if (picker === 'group') {
    return groupMapped.map(item =>
      item.group ? item : { ...item, group: 'Other' }
    );
  }
};
