import React from 'react';
import { Popover, Dropdown, Whisper, IconButton, Icon } from 'rsuite';
import fileExtension from 'file-extension';
import saveAs from 'file-saver';
import viewMap from '../../lib/viewMap';

const MenuPopover = ({
  onSelect,
  togglePreview,
  previewVisable,
  downloadVisable,
  download,
  editVisable,
  editMetadata,
  ...rest
}) => (
  <Popover {...rest} full>
    <Dropdown.Menu onSelect={onSelect}>
      {editVisable && (
        <Dropdown.Item
          onClick={e => {
            e.stopPropagation();
            editMetadata();
          }}
        >
          Edit
        </Dropdown.Item>
      )}
      {previewVisable && (
        <Dropdown.Item
          onClick={e => {
            e.stopPropagation();
            togglePreview();
          }}
        >
          Preview
        </Dropdown.Item>
      )}
      {downloadVisable && (
        <Dropdown.Item
          onClick={e => {
            e.stopPropagation();
            download();
          }}
        >
          Download
        </Dropdown.Item>
      )}
    </Dropdown.Menu>
  </Popover>
);

class WithPopover extends React.Component {
  handleSelectMenu = () => {
    this.trigger.hide();
  };
  render() {
    return (
      <Whisper
        placement="autoVerticalLeft"
        trigger="click"
        onClick={e => e.stopPropagation()}
        triggerRef={ref => {
          this.trigger = ref;
        }}
        speaker={
          <MenuPopover
            onSelect={this.handleSelectMenu}
            togglePreview={this.props.togglePreview}
            download={this.props.download}
            previewVisable={this.props.previewVisable}
            downloadVisable={this.props.downloadVisable}
            editVisable={this.props.editVisable}
            editMetadata={this.props.editMetadata}
          />
        }
      >
        <IconButton appearance="subtle" icon={<Icon icon="more" />} />
      </Whisper>
    );
  }
}

export const ActionCell = ({
  rowData,
  previewToggle,
  updatePreview,
  editMetadata,
  ...props
}) => (
  <WithPopover
    download={() => saveAs(rowData.link, rowData.metadata.name)}
    togglePreview={() => {
      updatePreview(rowData);
      previewToggle.toggle();
    }}
    downloadVisable={rowData.download}
    previewVisable={viewMap.includes(fileExtension(rowData.metadata.name))}
    editVisable={rowData.edit}
    editMetadata={() => editMetadata(rowData)}
  />
);
