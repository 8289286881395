export default [
  'aac',
  'aif',
  'aifc',
  'aiff',
  'amr',
  'au',
  'flac',
  'm3u',
  'm4a',
  'mid',
  'mp3',
  'ra',
  'wav',
  'wma',
  'wpl',
  'as',
  'as3',
  'asm',
  'aspx',
  'cpp',
  'bat',
  'c',
  'cc',
  'cmake',
  'cs',
  'css',
  'cxx',
  'db',
  'diff',
  'erb',
  'groovy',
  'h',
  'haml',
  'hh',
  'htm',
  'html',
  'java',
  'js',
  'less',
  'm',
  'make',
  'md',
  'ml',
  'mm',
  'php',
  'pl',
  'plist',
  'properties',
  'py',
  'rb',
  'sass',
  'scala',
  'script',
  'scm',
  'sml',
  'sql',
  'sh',
  'wabba',
  'yaml',
  'txt',
  'vi',
  'vim',
  'webdoc',
  'bmp',
  'gif',
  'gdraw',
  'jpeg',
  'jpg',
  'png',
  'ps',
  'svs',
  'svg',
  'tif',
  'tiff',
  '3g2',
  '3gp',
  'avi',
  'flv',
  'm2v',
  'm2ts',
  'm4v',
  'mkv',
  'mov',
  'mp4',
  'mpeg',
  'mpg',
  'ogg',
  'mts',
  'qt',
  'wmv',
  'pdf',
  'xls',
  'xlsx',
  'xlsm',
  'eps',
  'key',
  'numbers',
  'pages',
  'odp',
  'otp',
  'pot',
  'potx',
  'ppt',
  'pptx',
  'pptm',
  'psd',
  'ods',
  'xlt',
  'xltx',
  'docx',
  'doc',
  'docm',
];
