import React from 'react';
import { Box, Grid, Flex, Inline, styled } from 'reakit';
import FileIcon from '../FileIcon';
import fileExtension from 'file-extension';
import filesize from 'filesize';
import { X } from 'react-feather';

const FileStatus = styled(Box)`
  font-size: 11px !important;
  line-height: 11px !important;
  font-weight: 400 !important;
  color: #6d6d6d !important;
  margin-bottom: 4px !important;
`;

const FileTitle = styled(Box)`
  word-break: break-all !important;
  white-space: normal !important;
  overflow: initial !important;
  font-size: 11px !important;
  line-height: 1.35 !important;
  font-weight: 500 !important;
  margin: 0 !important;
  padding: 0 !important;
  max-height: 28px !important;
  margin-bottom: 5px !important;
  text-overflow: ellipsis !important;
`;

const FileInfo = ({ file, editFile }) => (
  <Box margin="8px 3px 0">
    <FileTitle>{file.name}</FileTitle>
    <FileStatus>
      {filesize(file.size)} -{' '}
      <Inline cursor="pointer" onClick={editFile}>
        Edit
      </Inline>
    </FileStatus>
  </Box>
);

const FilePreview = ({ name, removeFile }) => (
  <Flex
    relative
    width={140}
    height={100}
    backgroundColor="rgb(203, 203, 203)"
    alignItems="center"
    justifyContent="center"
    borderRadius="3px"
  >
    <Flex
      absolute
      width={20}
      height={20}
      top={-10}
      right={-10}
      borderRadius={10}
      color="white"
      backgroundColor="black"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      onClick={removeFile}
    >
      <X width={15} height={15} />
    </Flex>
    <FileIcon dimension={80} extension={fileExtension(name)} />
  </Flex>
);

export const FileItem = ({ file, removeFile, editFile }) => (
  <Grid.Item>
    <FilePreview name={file.name} removeFile={() => removeFile(file)} />
    <FileInfo file={file} editFile={() => editFile(file)} />
  </Grid.Item>
);
