import 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import is from 'ramda/src/is';

const ellipsis = `white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;`;

export const Cell = ({
  accessor,
  rowData,
  rowIndex,
  wordWrap,
  align,
  children,
  ...restProps
}) => {
  const cellValue = is(String, accessor)
    ? rowData[accessor]
    : accessor(rowData);
  let defaultStyles = [
    `color: #575757;
  border-bottom: 1px solid #f2f2f5;`,
  ];
  if (!children) {
    defaultStyles.push(`padding: 13px 10px;
    text-align: ${align};`);
    if (!wordWrap) {
      defaultStyles.push(ellipsis);
    }
  }
  return (
    <div css={defaultStyles} {...restProps}>
      {children
        ? children({ rowData, rowIndex, accessor, cellValue })
        : cellValue}
    </div>
  );
};
Cell.propTypes = {
  accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  wordWrap: PropTypes.bool,
};
Cell.defaultProps = {
  wordWrap: true,
};
