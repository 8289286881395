import React, { Fragment } from 'react';
import { Box, Toolbar, Link, Button, Grid, Tooltip } from 'reakit';
import { Plus } from 'react-feather';
import Pluralize from 'react-pluralize';
import ReactLoading from 'react-loading';

import Panel from '../Panel';
import { FileItem } from '../FileItem';

const DashboardHeader = ({ openFileDialog, clearFiles, filesCount }) => (
  <Panel.Header>
    <Toolbar.Content>
      <Toolbar.Focusable as={Link} onClick={clearFiles}>
        Cancel
      </Toolbar.Focusable>
    </Toolbar.Content>
    <Toolbar.Content align="center" gridAutoColumns="auto">
      <Box>
        <Pluralize singular="file" count={filesCount} /> selected
      </Box>
    </Toolbar.Content>
    <Toolbar.Content align="end">
      <Toolbar.Focusable as={Plus} onClick={openFileDialog} />
    </Toolbar.Content>
  </Panel.Header>
);

const DashboardFooter = ({
  uploadFiles,
  filesCount,
  isUploading,
  isInvalid,
}) => (
  <Panel.Footer>
    <Toolbar.Content>
      <Box>
        <Toolbar.Focusable
          as={Button}
          backgroundColor="#1bb240"
          color="white"
          border="none"
          onClick={uploadFiles}
          disabled={isUploading || isInvalid}
        >
          {isUploading ? (
            <Fragment>
              <ReactLoading type="spin" height={24} width={24} />
              <Box>Uploading</Box>
            </Fragment>
          ) : (
            <Box>
              Upload <Pluralize singular="file" count={filesCount} />
            </Box>
          )}
        </Toolbar.Focusable>
        {isInvalid && (
          <Tooltip placement="right" fontSize={11}>
            <Tooltip.Arrow />
            Add all required fields to files before you can upload.
          </Tooltip>
        )}
      </Box>
    </Toolbar.Content>
  </Panel.Footer>
);

const DashboardContent = ({ files, removeFile, editFile }) => (
  <Panel.Content>
    <Grid columns="repeat(auto-fit, 140px)" autoRows="auto" gap={40}>
      {files.map(file => (
        <FileItem
          key={file.fileObj.preview}
          file={file}
          removeFile={removeFile}
          editFile={editFile}
        />
      ))}
    </Grid>
  </Panel.Content>
);

export default ({
  openFileDialog,
  files,
  clearFiles,
  uploadFiles,
  editFile,
  removeFile,
  isUploading,
  isInvalid,
}) => (
  <Panel>
    <DashboardHeader
      filesCount={files.length}
      clearFiles={clearFiles}
      openFileDialog={openFileDialog}
    />
    <DashboardContent
      files={files}
      removeFile={removeFile}
      editFile={editFile}
    />
    <DashboardFooter
      filesCount={files.length}
      uploadFiles={uploadFiles}
      isUploading={isUploading}
      isInvalid={isInvalid}
    />
  </Panel>
);
