import React from 'react';
import { Layout, Nav } from '../../components';
import Uploader from '../../components/Uploader';
import { ToastProvider, ToastConsumer } from 'react-toast-notifications';

export default ({ fdrApplicantId, accessToken }) => {
  return (
    <ToastProvider>
      <Layout>
        {fromSalesforce => (
          <Nav
            fdrApplicantId={fdrApplicantId}
            fromSalesforce={fromSalesforce}
          />
        )}
        {fromSalesforce => (
          <ToastConsumer>
            {({ add: addToast }) => (
              <Uploader
                fromSalesforce={fromSalesforce}
                dropdownSelector="group"
                applicantId={fdrApplicantId}
                onFilesRejected={files => {
                  addToast(
                    `These files are above the 128MB max file size. Files: ${files
                      .map(file => file.name)
                      .join(', ')}`,
                    {
                      appearance: 'error',
                      autoDismiss: true,
                      autoDismissTimeout: 5000,
                    }
                  );
                }}
                onUploadComplete={() =>
                  addToast(
                    'Selected files have been successfully uploaded and can now see in the documents view.',
                    {
                      appearance: 'success',
                      autoDismiss: true,
                    }
                  )
                }
                onUploadFailed={() =>
                  addToast(
                    'An error has occured while uploading. Try again later.',
                    {
                      appearance: 'error',
                      autoDismiss: true,
                      autoDismissTimeout: 5000,
                    }
                  )
                }
                bearerToken={accessToken}
              />
            )}
          </ToastConsumer>
        )}
      </Layout>
    </ToastProvider>
  );
};
