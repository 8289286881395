import React from 'react';
import { Box, Toolbar, Button, Grid, Flex } from 'reakit';
import fileExtension from 'file-extension';
import Panel from '../Panel';
import FileIcon from '../FileIcon';
import { InputPicker, Input, Cascader } from 'rsuite';

const ContentPreview = ({ name }) => (
  <Flex
    height={200}
    backgroundColor="rgb(203, 203, 203)"
    alignItems="center"
    justifyContent="center"
  >
    <FileIcon dimension={80} extension={fileExtension(name)} />
  </Flex>
);

const TestPicker = ({ picker, doctypes, setDoctype, value }) => {
  if (picker === 'cascader') {
    return (
      <Cascader
        data={doctypes}
        onSelect={({ value, label }) => value !== label && setDoctype(value)}
        menuWidth={250}
        placeholder="Select Document Type"
        value={value}
      />
    );
  }
  return (
    <InputPicker
      placement="topLeft"
      value={value}
      valueKey="slug"
      data={doctypes}
      onSelect={setDoctype}
      groupBy="group"
      placeholder="Select Document Type"
      maxHeight={250}
      block
      cleanable={false}
    />
  );
};

const FileMetaData = ({
  file,
  doctypes,
  setDoctype,
  setPartnerName,
  setDebtAccount,
  dropdownSelector,
  debtAccounts,
  fromSalesforce,
}) => (
  <Box padding="30px 20px 20px">
    <Grid alignItems="center" columns="1fr 3fr" marginBottom={15}>
      <Box htmlFor="name">Name</Box>
      <Input id="name" value={file.name} disabled />
    </Grid>
    <Grid alignItems="center" columns="1fr 3fr" marginBottom={15}>
      <Box htmlFor="name">
        Document Type <small>(Required)</small>
      </Box>
      <TestPicker
        picker={dropdownSelector}
        doctypes={doctypes}
        setDoctype={setDoctype}
        value={file.attributes.document_type}
      />
    </Grid>
    {['notarized_partner_atc', 'partner_atc', 'power_of_attorney'].includes(
      file.attributes.document_type
    ) && (
      <Grid alignItems="center" columns="1fr 3fr" marginBottom={15}>
        <Box htmlFor="name">
          Partner Name <small>(Required)</small>
        </Box>
        <InputPicker
          placement="topLeft"
          value={file.attributes.partner_name}
          data={[
            { label: 'McCarthy', value: 'McCarthy' },
            { label: 'Zachary', value: 'Zachary' },
            { label: 'NLLG', value: 'NLLG' },
            { label: 'Turnbull', value: 'Turnbull' },
            { label: 'Gitmeid', value: 'Gitmeid' },
          ]}
          onSelect={setPartnerName}
          placeholder="Select Partner Name"
          maxHeight={250}
          block
          cleanable={false}
        />
      </Grid>
    )}
    {fromSalesforce && (
      <Grid alignItems="center" columns="1fr 3fr" marginBottom={15}>
        <Box htmlFor="name">Debt Account</Box>
        <InputPicker
          placement="topLeft"
          value={file.attributes.fdr_sf_debt_account_id}
          data={debtAccounts}
          onSelect={setDebtAccount}
          placeholder="Select Debt Account"
          maxHeight={250}
          block
          cleanable={false}
        />
      </Grid>
    )}
  </Box>
);

const EditFileHeader = ({ name }) => (
  <Panel.Header>
    <Toolbar.Content align="center" gridAutoColumns="auto">
      <Box>Editing {name}</Box>
    </Toolbar.Content>
  </Panel.Header>
);

const EditFileFooter = ({ saveFile, clearEditFile }) => (
  <Panel.Footer>
    <Toolbar.Content>
      <Toolbar.Focusable
        as={Button}
        backgroundColor="#1bb240"
        color="white"
        border="none"
        onClick={saveFile}
      >
        <Box>Save Changes</Box>
      </Toolbar.Focusable>
      <Toolbar.Focusable
        as={Button}
        color="inherit"
        background="none"
        border="none"
        onClick={clearEditFile}
      >
        <Box>Cancel</Box>
      </Toolbar.Focusable>
    </Toolbar.Content>
  </Panel.Footer>
);

const EditFileContent = ({
  file,
  doctypes,
  setDoctype,
  setPartnerName,
  dropdownSelector,
  debtAccounts,
  fromSalesforce,
  setDebtAccount,
}) => (
  <Panel.Content>
    <ContentPreview name={file.name} />
    <FileMetaData
      file={file}
      doctypes={doctypes}
      setDoctype={setDoctype}
      setPartnerName={setPartnerName}
      dropdownSelector={dropdownSelector}
      debtAccounts={debtAccounts}
      fromSalesforce={fromSalesforce}
      setDebtAccount={setDebtAccount}
    />
  </Panel.Content>
);

export const EditFile = ({
  file,
  doctypes,
  setDoctype,
  setPartnerName,
  clearEditFile,
  saveFile,
  dropdownSelector,
  debtAccounts,
  fromSalesforce,
  setDebtAccount,
}) => (
  <Panel>
    <EditFileHeader name={file.name} />
    <EditFileContent
      file={file}
      doctypes={doctypes}
      setDoctype={setDoctype}
      setPartnerName={setPartnerName}
      dropdownSelector={dropdownSelector}
      debtAccounts={debtAccounts}
      fromSalesforce={fromSalesforce}
      setDebtAccount={setDebtAccount}
    />
    <EditFileFooter saveFile={saveFile} clearEditFile={clearEditFile} />
  </Panel>
);
