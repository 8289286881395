import PropTypes from 'prop-types';

export const Column = () => null;
Column.propTypes = {
  width: PropTypes.number,
  flexGrow: PropTypes.number,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  sortable: PropTypes.bool,
  minWidth: PropTypes.number,
  wordWrap: PropTypes.bool,
};
Column.defaultProps = {
  sortable: false,
  align: 'left',
  minWidth: 200,
};
