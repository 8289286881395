import React from 'react';

import { FriendlyDate } from '../';

export default ({
  created_at,
  modified_at,
  uploaded_by,
  size,
  metadata: {
    document_type,
    name,
    fdr_sf_debt_account_id,
    currentBalance,
    newAccountNumber,
    newCreditorName,
    originalPrevCreditor,
    methodOfService,
    dateMailReceived,
    litServiceDate,
    partner_name,
    visualvault_created_at,
  },
}) => (
  <div>
    <div>Name: {name}</div>
    {visualvault_created_at ? (
      <>
        <div>
          Uploaded At: <FriendlyDate>{visualvault_created_at}</FriendlyDate>
        </div>
        <div>
          Migrated At:{' '}
          <FriendlyDate>
            {created_at === '0001-01-01T00:00:00Z'
              ? '2018-11-01T00:00:00Z'
              : created_at}
          </FriendlyDate>
        </div>
      </>
    ) : (
      <div>
        Uploaded At:{' '}
        <FriendlyDate>
          {created_at === '0001-01-01T00:00:00Z'
            ? '2018-11-01T00:00:00Z'
            : created_at}
        </FriendlyDate>
      </div>
    )}
    <div>
      Last Modified:{' '}
      <FriendlyDate>
        {modified_at === '0001-01-01T00:00:00Z'
          ? '2018-11-01T00:00:00Z'
          : modified_at}
      </FriendlyDate>
    </div>
    <div>Uploaded By: {uploaded_by}</div>
    <div>Size: {size}</div>
    <div>Document Type: {document_type}</div>
    <div>FDR SF Debt Account ID: {fdr_sf_debt_account_id}</div>
    {partner_name ? <div>Partner Name: {partner_name}</div> : null}
    {currentBalance ? <div>Current Balance: {currentBalance}</div> : null}
    {newAccountNumber ? (
      <div>New Account Number: {newAccountNumber}</div>
    ) : null}
    {newCreditorName ? <div>New Creditor: {newCreditorName}</div> : null}
    {originalPrevCreditor ? (
      <div>Original Creditor: {originalPrevCreditor}</div>
    ) : null}
    {methodOfService ? <div>Method of Service: {methodOfService}</div> : null}
    {dateMailReceived ? (
      <div>Date Mail Received: {dateMailReceived}</div>
    ) : null}
    {litServiceDate ? <div>LIT Service Date: {litServiceDate}</div> : null}
  </div>
);
