import React from 'react';

export const IconFilePages = ({ height = 32, width = 32 }) => (
  <svg width={width} height={height} viewBox="0 0 32 32">
    <path
      d="M7 26.5v-21a.47.47 0 0 1 .5-.5H20v4a.94.94 0 0 0 1 1h4v12H10.5a.5.5 0 0 0 0 1H25v3.5a.47.47 0 0 1-.5.5h-17a.47.47 0 0 1-.5-.5z"
      fill="#fff"
    />
    <path
      d="M25 26.5V23H10.5a.5.5 0 0 1 0-1H25V10h-4a.94.94 0 0 1-1-1V5H7.5a.47.47 0 0 0-.5.5v21a.47.47 0 0 0 .5.5h17a.47.47 0 0 0 .5-.5zm1-16.6v16.6a1.54 1.54 0 0 1-1.5 1.5h-17A1.54 1.54 0 0 1 6 26.5v-21A1.54 1.54 0 0 1 7.5 4h12.6z"
      fill="#f49d31"
    />
    <path
      d="M20.3 11l-1 1 .8.8 1-1a.57.57 0 0 0-.8-.8zm-9.7 10.3c-.1.1.1.3.2.2l.8-.5-.5-.5zm8.5-9.1L12 19.3a3.85 3.85 0 0 0-.8 1.1l.5.5a4.44 4.44 0 0 0 1.1-.8l7.1-7.1z"
      fill="#f49d31"
    />
  </svg>
);
