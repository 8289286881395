import React from 'react';
import { Box, Grid, Toolbar } from 'reakit';

const Panel = props => (
  <Box
    as={Grid}
    backgroundColor="#fafafa"
    rows="auto 1fr auto"
    columns="1fr"
    height="100%"
    border="1px solid rgba(0, 0, 0, 0.3)"
    borderRadius="0.25em"
    {...props}
  />
);

Panel.Header = props => (
  <Grid.Item row="1">
    <Toolbar
      fontSize={16}
      gutter="8px 16px"
      borderBottom="1px solid rgba(0,0,0,0.3)"
      {...props}
    />
  </Grid.Item>
);

Panel.Footer = props => (
  <Grid.Item row="3">
    <Toolbar
      fontSize={16}
      gutter="8px 16px"
      borderTop="1px solid rgba(0,0,0,0.3)"
      {...props}
    />
  </Grid.Item>
);

Panel.Content = props => (
  <Grid.Item row="2" padding={20} overflowY="scroll" {...props} />
);
export { Panel };
export default Panel;
