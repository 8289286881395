import React from 'react';
import { Box, Flex, Link } from 'reakit';
import { Upload } from 'react-feather';

export default ({ openFileDialog }) => (
  <Box
    as={Flex}
    height="100%"
    alignItems="center"
    justifyContent="center"
    border="1px solid rgba(0, 0, 0, 0.3)"
    borderRadius="0.25em"
    column
  >
    <Upload />
    <p>
      Drop files here, or{' '}
      <Link onClick={() => openFileDialog()} href="#">
        click here
      </Link>{' '}
      select files to upload.
    </p>
  </Box>
);
