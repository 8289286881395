import React, { useState } from 'react';
import { Box, Backdrop, Overlay, Portal, Flex, Heading } from 'reakit';
import ReactLoading from 'react-loading';
import Viewer from '@achieve/react-viewer';
import fileExtension from 'file-extension';

const LoadingComponent = ({ overlay }) => (
  <Flex
    onClick={overlay.hide}
    column
    width="100%"
    height="100%"
    alignItems="center"
    justifyContent="center"
  >
    <ReactLoading type="spokes" />
    <Heading color="#FFF">Loading</Heading>
  </Flex>
);

export const FileViewer = ({ children }) => {
  const [value, updatePreview] = useState();
  return (
    <Overlay.Container>
      {overlay => (
        <>
          {children({
            previewToggle: {
              as: Overlay.Toggle,
              ...overlay,
            },
            updatePreview,
          })}
          <Backdrop
            as={[Portal, Overlay.Toggle]}
            {...overlay}
            onClick={() => updatePreview(null)}
          />
          <Overlay
            as={Portal}
            {...overlay}
            backgroundColor="rgba(0,0,0,0.0)"
            padding={0}
          >
            <Box width="80vw" height="80vh">
              {value && (
                <Viewer
                  {...value}
                  extension={fileExtension(value.metadata.name)}
                  converterUrl={''}
                />
              )}
            </Box>
          </Overlay>
        </>
      )}
    </Overlay.Container>
  );
};
