import 'styled-components/macro';
import React from 'react';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { Value } from 'react-powerplug';

import {
  Error,
  FileViewer,
  Layout,
  Nav,
  ModalForm,
  DocumentTable,
} from '../../components';
import * as api from '../../lib/api';

const getComputedFields = (slug, doctypeMap) => ({
  doctypeLabel: doctypeMap[slug].label,
  download: doctypeMap[slug].permission.download,
  edit: doctypeMap[slug].permission.edit,
});
const getTime = ({ created_at, metadata: { visualvault_created_at } }) =>
  visualvault_created_at ||
  (created_at === '0001-01-01T00:00:00Z' ? '2018-11-01T00:00:00Z' : created_at);
export default class Documents extends React.Component {
  state = {
    documents: [],
    doctypes: [],
    debtAccounts: [],
    loading: true,
    error: null,
    doctypeMap: null,
    perimissionMap: null,
  };
  async componentDidMount() {
    try {
      const { accessToken, fdrApplicantId } = this.props;
      const searchParams = new URLSearchParams(window.location.search);
      const fromSalesforce = searchParams.get('from') === 'salesforce';
      let promises = [
        api.fetchDocuments(accessToken, { fdrApplicantId }),
        api.fetchDoctypes(accessToken),
      ];
      if (fromSalesforce) {
        promises = promises.concat(
          api.fetchDebtAccounts(accessToken, { fdrApplicantId })
        );
      }
      let [documents, doctypes, debtAccounts = {}] = await Promise.all(
        promises
      );
      const doctypeMap = R.indexBy(R.prop('slug'))(doctypes);
      documents = documents.map(item => ({
        ...item,
        ...getComputedFields(item.metadata.document_type, doctypeMap),
        time: DateTime.fromISO(getTime(item)),
      }));
      this.setState({
        debtAccounts: Object.entries(debtAccounts).map(([value, label]) => ({
          label,
          value,
        })),
        documents,
        doctypes,
        loading: false,
        doctypeMap,
      });
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  }

  updateMetadata = async (initialFile, newMetadata) => {
    const changedMetadata = { ...initialFile.metadata, ...newMetadata };
    const { accessToken, fdrApplicantId } = this.props;
    const { doctypeMap } = this.state;
    try {
      await api.updateMetadata(accessToken, {
        fdrApplicantId,
        newMetadata: changedMetadata,
        documentId: initialFile.id,
      });
      const newFile = {
        ...initialFile,
        ...getComputedFields(changedMetadata.document_type, doctypeMap),
        metadata: changedMetadata,
        modified_at: DateTime.local(),
      };
      this.setState(prevState => ({
        ...prevState,
        documents: prevState.documents.map(item =>
          item.id === newFile.id ? newFile : item
        ),
      }));
    } catch (error) {}
  };
  render() {
    const { fdrApplicantId } = this.props;
    const { loading, error, documents, doctypes, debtAccounts } = this.state;
    return (
      <Layout>
        {fromSalesforce => (
          <Nav
            fdrApplicantId={fdrApplicantId}
            fromSalesforce={fromSalesforce}
          />
        )}
        {error ? (
          <Error
            errorMessage={
              'You do not have sufficient permissions to view this users documents.\nPlease submit a request to update your Single Sign On profile.'
            }
          />
        ) : (
          <Value initial={{}}>
            {({ value, set, reset }) => (
              <ModalForm
                debtAccounts={debtAccounts}
                doctypes={doctypes.filter(
                  ({ slug }) =>
                    !['historic_data', 'customer_upload'].includes(slug)
                )}
                title="Edit Metadata"
                data={
                  R.isEmpty(value)
                    ? value
                    : R.pick(['name', 'document_type'], value.metadata)
                }
                handleReset={() => reset()}
                onSubmit={values => this.updateMetadata(value, values)}
              >
                {({ openModal }) => (
                  <FileViewer>
                    {({ previewToggle, updatePreview }) => (
                      <DocumentTable
                        data={documents}
                        loading={loading}
                        previewToggle={previewToggle}
                        updatePreview={updatePreview}
                        editMetadata={file => {
                          set(file);
                          openModal();
                        }}
                      />
                    )}
                  </FileViewer>
                )}
              </ModalForm>
            )}
          </Value>
        )}
      </Layout>
    );
  }
}
