import 'styled-components/macro';
import React from 'react';
import { Loader } from 'rsuite';
export const Loading = ({ loadingMessage = 'Loading...' }) => (
  <div
    css={`
      grid-row: 2;
      grid-column: 1 / -1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #575757;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      z-index: 1;
      background-color: hsla(0, 0%, 100%, 0.9);
      & .rs-loader-content {
        font-size: 14px !important;
      }
    `}
  >
    <Loader content={loadingMessage} />
  </div>
);
