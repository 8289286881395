import 'styled-components/macro';
import React from 'react';

export const NoData = ({ noDataMessage = 'No data found' }) => (
  <div
    css={`
      grid-row: 2;
      grid-column: 1 / -1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #575757;
    `}
  >
    {noDataMessage}
  </div>
);
