import 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import { Sort, SortDown, SortUp } from 'styled-icons/fa-solid';
import { is } from 'ramda';
const sortStyles = `
color: #34c3ff;
padding-left: 5px;
`;

const toggle = sortType => (sortType === 'ascend' ? 'descend' : 'ascend');

export const Sortable = ({ sortKey, sortState }) => {
  if (sortState[sortKey] === 'descend') {
    return <SortDown size={13} css={sortStyles} />;
  } else if (sortState[sortKey] === 'ascend') {
    return <SortUp size={13} css={sortStyles} />;
  }
  return <Sort size={13} css={sortStyles} />;
};

export const Header = ({
  align,
  sortable,
  sortState,
  sortKey,
  onSortColumn,
  children,
  ...props
}) => {
  const onClick = () => onSortColumn({ [sortKey]: toggle(sortState[sortKey]) });
  return (
    <div
      css={`
        color: #8e8e93;
        padding: 10px;
        border-bottom: 1px solid #f2f2f5;
        font-size: 12px;
        line-height: 1.66666667;
        text-align: ${align};
        cursor: ${sortable ? 'pointer' : 'initial'};
      `}
      onClick={sortable ? onClick : undefined}
      {...props}
    >
      {is(Function, children) ? (
        children({ sortable, sortState, sortKey })
      ) : (
        <>
          {children}
          {sortable && <Sortable sortKey={sortKey} sortState={sortState} />}
        </>
      )}
    </div>
  );
};
Header.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
};
