import React from 'react';

export const IconFileVideo = ({ height = 32, width = 32 }) => (
  <svg width={width} height={height} viewBox="0 0 32 32">
    <path fill="#fff" d="M25 27H7V5h13l5 5v17z" />
    <path
      d="M20 4H7a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9.89zm5 23H7V5h13v4a1 1 0 0 0 1 1h4z"
      fill="#22a7f0"
    />
    <path
      d="M12.43 21.8c-.24.13-.43 0-.43-.27v-8c0-.28.2-.4.43-.27l7.14 4a.25.25 0 0 1 0 .48z"
      fill="#22a7f0"
    />
  </svg>
);
