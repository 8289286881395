import * as R from 'ramda';
import * as R_ from 'ramda-extension';
import { DateTime } from 'luxon';

export const insertEach = R.curry((postions, insertElms, orgArr) =>
  R.reduce(
    (acc, value) => R.insert(...value, acc),
    orgArr,
    R.zip(postions, insertElms)
  )
);

export const incrementAccum = R_.mapIndexed((val, index) => val + index + 1);

export const findAllIndexes = R.curry((prop, values, dataArr) =>
  R.pipe(
    R.map(R_.dotPathEq(prop)),
    R.map(R.findIndex),
    R.map(R.applyTo(dataArr))
  )(values)
);

const caseInsensitiveContains = v =>
  R.pipe(
    R.toLower,
    R.contains(R.toLower(v))
  );

export const whereAny = R.pipe(
  R.toPairs,
  R.filter(([key, value]) => !R_.isFalsy(value)),
  R.map(([key, value]) => [
    key,
    R_.isFunction(value) ? value : caseInsensitiveContains(value),
  ]),
  R.map(R.apply(R.flip(R_.dotPathSatisfies))),
  R.ifElse(R.length, R.identity, R.always([R.T])),
  R.allPass
);

export const produce = R.curry((conditions, argument) =>
  R.pipe(
    R.toPairs,
    R.map(([key, condition]) => condition(argument))
  )(conditions)
);

export const fromSortObj = R.pipe(
  R.toPairs,
  R.map(([sortColumn, sortType]) =>
    R[sortType](
      R.pipe(
        R_.dotPath(sortColumn),
        R.cond([
          [DateTime.isDateTime, o => o.toMillis()],
          [R_.isString, R.toUpper],
          [R_.isNumber, R.identity],
        ])
      )
    )
  )
);
