import React from 'react';
import * as Icons from './fileIcons';

const mirror = values =>
  values.reduce((prev, cur) => {
    prev[cur] = cur;
    return prev;
  }, {});

const EXTENSIONS = {
  IconFileAudio: mirror([
    'aac',
    'aif',
    'aifc',
    'aiff',
    'amr',
    'au',
    'flac',
    'm3u',
    'm4a',
    'mid',
    'mp3',
    'ra',
    'wav',
    'wma',
    'wpl',
  ]),
  IconFileBoxNote: mirror(['boxnote']),
  IconFileCode: mirror([
    'as',
    'as3',
    'asm',
    'aspx',
    'c',
    'cpp',
    'bat',
    'c',
    'cc',
    'cmake',
    'cs',
    'css',
    'cxx',
    'db',
    'diff',
    'erb',
    'groovy',
    'h',
    'haml',
    'hh',
    'htm',
    'html',
    'java',
    'js',
    'less',
    'm',
    'make',
    'md',
    'ml',
    'mm',
    'php',
    'pl',
    'plist',
    'properties',
    'py',
    'rb',
    'sass',
    'scala',
    'script',
    'scm',
    'sml',
    'sql',
    'sh',
    'wabba',
    'yaml',
  ]),
  IconFileDocument: mirror([
    'csv',
    'dot',
    'dotx',
    'msg',
    'odt',
    'rtf',
    'tsv',
    'wpd',
    'xhtml',
    'xml',
    'xsd',
    'xsl',
  ]),
  IconFileExcelSpreadsheet: mirror(['xls', 'xlsx', 'xlsm']),
  IconFileGoogleDocs: mirror(['gdoc']),
  IconFileGoogleSheets: mirror(['gsheet']),
  IconFileGoogleSlides: mirror(['gslide', 'gslides']),
  IconFileVector: mirror(['eps']),
  IconFileIllustrator: mirror(['ai']),
  IconFileIndesign: mirror(['indd']),
  IconFileKeynote: mirror(['key']),
  IconFileNumbers: mirror(['numbers']),
  IconFilePages: mirror(['pages']),
  IconFileImage: mirror([
    'bmp',
    'gif',
    'gdraw',
    'jpeg',
    'jpg',
    'png',
    'ps',
    'svs',
    'svg',
    'tif',
    'tiff',
  ]),
  IconFilePDF: mirror(['pdf']),
  IconFilePresentation: mirror(['odp', 'otp', 'pot', 'potx']),
  IconFilePowerpointPresentation: mirror(['ppt', 'pptx', 'pptm']),
  IconFilePhotoshop: mirror(['psd']),
  IconFileSpreadsheet: mirror(['ods', 'xlt', 'xltx']),
  IconFileText: mirror(['txt', 'vi', 'vim', 'webdoc']),
  IconFileThreeD: mirror(['3ds', 'dae', 'fbx', 'obj', 'ply', 'stl']),
  IconFileVideo: mirror([
    '3g2',
    '3gp',
    'avi',
    'flv',
    'm2v',
    'm2ts',
    'm4v',
    'mkv',
    'mov',
    'mp4',
    'mpeg',
    'mpg',
    'ogg',
    'mts',
    'qt',
    'wmv',
  ]),
  IconFileWordDocument: mirror(['docx', 'doc', 'docm']),
  IconFileZip: mirror(['rar', 'tgz', 'zip']),
};

const getFileIconComponent = extension => {
  const extensionComponentName = Object.keys(EXTENSIONS).filter(
    extensionComponent => !!EXTENSIONS[extensionComponent][extension]
  )[0];
  return extensionComponentName || 'IconFileDefault';
};

export const FileIcon = ({
  dimension = 32,
  extension = '',
  title,
  ...props
}) => {
  const IconComponent = Icons[getFileIconComponent(extension)];
  return (
    <IconComponent
      height={dimension}
      width={dimension}
      title={title}
      {...props}
    />
  );
};

export default FileIcon;
